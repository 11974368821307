import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {graphql} from "gatsby";
import HeroVideo from "../components/GeneralComponents/HeroVideo";
import VideosGrid from "../components/VideosEPodcasts/VideosGrid";
import {Helmet} from "react-helmet";

const VideosPage = ({data}) => {
    return(
        <>
            <Helmet>
                <html lang="pt-BR" amp/>
                <meta charSet="UTF-8"/>
                <title>Cia. Ibérica - Vídeos</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/videos/"/>
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large"/>
                <meta property="og:locale" content="pt-BR"/>
                <meta property="og:title" content="Cia. Ibérica - Vídeos"/>
                <meta property="og:description"
                      content="Confira os vídeos da Cia. Ibérica, empresa brasileira e com tradição no mercado."/>
                <meta property="og:url" content="https://www.ciaiberica.com.br/videos/"/>
                <meta property="og:sitename" content="Cia. Ibérica"/>
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            </Helmet>
            <Header />
            <HeroVideo props={data} />
            <VideosGrid props={data} />
            <Footer />
        </>
    )
}

export const query = graphql`
    query VideosQuery {
        videos: allContentfulVideos(filter: {node_locale: {eq: "en-US"}}) {
            edges {
                node {
                    id
                    urlVideo
                    titulo
                    descricao {
                        raw
                    }
                    videoYoutube
                    urlYoutube
                    videoContentful
                }
            }
            totalCount
        }
        video: contentfulAsset(title: {eq: "videos"}) {
            file {
              url
              contentType
            }
        }
        poster: contentfulAsset(title: {eq: "videos-poster"}) {
            file {
              url
              contentType
            }
        }
    }
`

export default VideosPage