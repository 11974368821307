import React, {Component} from 'react';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {BLOCKS, INLINES, MARKS} from "@contentful/rich-text-types";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import ReactPlayer from 'react-player/lazy'

class VideosGrid extends Component {

    render() {
        const options = {
            renderMark: {
                [MARKS.BOLD]: (text) => <b>{text}</b>
            },
            renderNode: {
                [INLINES.HYPERLINK]: (node, children) => {
                    const {uri} = node.data
                    return (
                        <a href={uri} style={{color: ""}} target="_blank" rel="noopener, noreferrer">
                            {children}
                        </a>
                    )
                },
                [BLOCKS.PARAGRAPH]: (node, children) => {
                    return <p className="card-body">{children}</p>
                },
                [BLOCKS.HEADING_2]: (node, children) => {
                    return <h2>{children}</h2>
                },

                [BLOCKS.EMBEDDED_ASSET]: (node) => {
                    const {gatsbyImageData, description} = node.data.target
                    return (
                        <GatsbyImage
                            image={getImage(gatsbyImageData)}
                            alt={description}
                        />
                    )
                },
            }
        }

        return (
            <div className="container">
                <div className="row mb-3 mt-3">
                    <h2 className="text-center">Vídeos</h2>
                </div>
                <div className="row">
                    {
                        this.props.props.videos.edges.slice().map(({node: video, index}) => {
                            return (
                                <div className="col-sm-12 iberica-grid-produto-col" key={video.urlVideo}>
                                    <div className="card iberica-grid-produto-card">
                                        <div className="card-img-top" >
                                            <ReactPlayer url={video.urlYoutube} width="auto"/>
                                            <h3 className="card-body">{video.titulo}</h3>
                                        </div>
                                        <div className="card-body">
                                            <Link to={`/videos/${video.urlVideo}/`} className="btn btn-primary">Ver
                                                Mais</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default VideosGrid;